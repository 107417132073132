import { NextSeo } from "next-seo";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Link from "next/link";

const FileNotFoundPage = () => {
  const { t } = useTranslation(["404"]);

  return (
    <>
      <NextSeo title={t("seo.title")} description={t("seo.description")} />
      <div className="flex flex-col items-center justify-center w-screen h-screen text-center">
        <span className="absolute bottom-8 text-neutral-500">Error 404</span>
        <h1 className="font-mono text-4xl font-semibold text-default">
          {t("pageNotFound")}
        </h1>
        <p className="px-4 mt-4 text-xl">{t("pageNotFoundMessage")}</p>
        <Link href="/">
          <a className="mt-8 button button-blue">{t("backToHome")}</a>
        </Link>
      </div>
    </>
  );
};

export default FileNotFoundPage;

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["404"])),
    },
  };
}
